header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8em;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials a{
    color: var(--color-primary)
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

.me {
    background: linear-gradient(var(--color-primary),transparent);
    width: 22rem;
    height: 28rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight:  200;
    font-size: 0.9rem;
    color: var(--color-primary)
}


/* ============== MEDIA MEDIUM DEVICES ============== */
@media screen and (max-width: 1024px){
    .container{
        height: 68vh;
    }
}

/* ============== MEDIA SMALL DEVICES ============== */
@media screen and (max-width: 600px){
    .container{
        height: 100vh;
    }

    .header__socials,
    .scroll__down{
        display: none;
    }
    .name{
        font-size: 1.75em;
    }
}