.hidden{
    display: none;
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}
.portfolio__small__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2.5rem;
}
.portfolio__detail__container {
    display: grid;
    grid-template-columns: 60% 1fr;
    gap: 2.5rem;
    margin-bottom: 2.5rem !important;
}

.portfolio__detail__container p{
    font-size: 1rem;
}

.portfolio__item__wide {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2.5rem;
}
.portfolio__item__wide hr{
    background: var(--color-white);
    height: 2px;
    margin-bottom: 1rem;
}



.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2.5rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__small__item {
    background: var(--color-bg-variant);
    padding: 0.7rem;
    border-radius: 2.5rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__small__item.active {
    background: var(--color-white);
}
.portfolio__small__item:hover {
    background: var(--color-white);
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    text-align: center;
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
.detail__title{
    width: max-content;
    display: inline-block;
    width: 100%;
    border-radius: 3rem;
}
.detail__title > .close__button-detail, .close__button-img {
    float: right;
}
.title{
    float:left;
    max-width: 90%;
}
.title-img{
    display:none;
}
.close__button-img, .close__button-detail {
    background: rgba(0,0,0,0.2);
    padding: 0.7rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}
.close__button, .close__button-detail :hover{
    background: rgba(0,0,0,0.2);
}

.close__button-img{
    display: none;
}
.close__button-detail{
    display: show;
}

.detail__title h2{
    font-size: 1.5 rem;
}

/* ============== MEDIA MEDIUM DEVICES ============== */
@media screen and (max-width: 1024px){
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    .portfolio__detail__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
        margin-bottom: 1.2rem !important;
    }
    .portfolio__detail__container p{
        font-size: 0.85rem;
    }
    .portfolio__small__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.5rem;
    }
    .close__button-img{
        display: flex;
        display: show !important;
    }
    .close__button-detail{
        display: none;
    }
}

/* ============== MEDIA SMALL DEVICES ============== */
@media screen and (max-width: 600px){
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__detail__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-bottom: 1rem !important;
    }
    .portfolio__detail__container p{
        font-size: 0.7.5rem;
    }
    .close__button-img{
        display: flex;
        display: show !important;
    }
    .close__button-detail{
        display: none;
    }
    .portfolio__small__container {
        display: none;
    }
    .close__button-img, .close__button-detail {
        padding: 0.5rem;
        font-size: 1rem;
    }
    .detail__title h2{
        font-size: 1.25rem;
    }
    .title{
        max-width: 80%;
    }
}